import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import Layout from './../components/layout'
import PageHeading from './../components/page-heading'
import Container from './../components/container'

export default function PageNotFound() {
  return (
    <Layout pageName="page-not-found" documentTitlePrefix="Page Not Found">
      <PageHeading heading="Page Not Found" />
      <Container>
        <Fade>
          <StyledNotFoundMessage>
            <p>Sorry, the page you are looking for cannot be found.</p>
            <p>
              Try going somewhere else using the navigation, or{' '}
              <Link to="/" className="header-heading-link">
                click here to go to the homepage
              </Link>
              .
            </p>
          </StyledNotFoundMessage>
        </Fade>
      </Container>
    </Layout>
  )
}

const StyledNotFoundMessage = styled.div`
  font-weight: 100;
  margin-top: 16rem;
  text-align: center;

  p {
    margin-bottom: 0;
  }
`
